import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_BaseNoProductFound = _resolveComponent("BaseNoProductFound");
    var _component_BaseProductCard = _resolveComponent("BaseProductCard");
    var _component_BaseFiveRowCol = _resolveComponent("BaseFiveRowCol");
    var _component_el_pagination = _resolveComponent("el-pagination");
    var _component_ModalProductDetail = _resolveComponent("ModalProductDetail");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () { return [
                (!_ctx.loading && _ctx.products.length === 0)
                    ? (_openBlock(), _createBlock(_component_BaseNoProductFound, {
                        key: 0,
                        loading: _ctx.loading,
                        products: _ctx.products
                    }, null, 8, ["loading", "products"]))
                    : _createCommentVNode("", true)
            ]; }),
            _: 1
        }),
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () { return [
                (!_ctx.loading && _ctx.products.length > 0)
                    ? (_openBlock(), _createBlock(_component_BaseFiveRowCol, { key: 0 }, {
                        default: _withCtx(function () { return [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, function (item) {
                                return (_openBlock(), _createBlock(_component_BaseProductCard, {
                                    key: item,
                                    product: item,
                                    wishlist: item.is_favourite,
                                    onQuickView: _ctx.quickView
                                }, null, 8, ["product", "wishlist", "onQuickView"]));
                            }), 128))
                        ]; }),
                        _: 1
                    }))
                    : _createCommentVNode("", true)
            ]; }),
            _: 1
        }),
        _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
        }, {
            default: _withCtx(function () {
                var _a, _b, _c, _d;
                return [
                    (!_ctx.loading && _ctx.products.length > 0)
                        ? (_openBlock(), _createBlock(_component_el_pagination, {
                            key: 0,
                            class: "text-center",
                            "onUpdate:currentPage": _ctx.currentPageChange,
                            "page-size": (_a = _ctx.pagination) === null || _a === void 0 ? void 0 : _a.rowsPerPage,
                            "current-page": (_b = _ctx.pagination) === null || _b === void 0 ? void 0 : _b.page,
                            layout: "prev, pager, next",
                            total: (_c = _ctx.pagination) === null || _c === void 0 ? void 0 : _c.total,
                            "page-count": (_d = _ctx.pagination) === null || _d === void 0 ? void 0 : _d.totalPage,
                            "hide-on-single-page": true,
                            background: ""
                        }, null, 8, ["onUpdate:currentPage", "page-size", "current-page", "total", "page-count"]))
                        : _createCommentVNode("", true)
                ];
            }),
            _: 1
        }),
        _createVNode(_component_ModalProductDetail, {
            product: _ctx.product,
            fullProductRoute: _ctx.fullProductRoute
        }, null, 8, ["product", "fullProductRoute"])
    ], 64));
}
