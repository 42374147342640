import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import BaseProductCard from "@/components/BaseProductCard.vue";
import { getProductList, showProduct } from "@/api/ProductApi";
import { useRoute } from "vue-router";
import { Modal } from "bootstrap";
import { topLoadingToggle } from "@/core/helpers/custom";
export default defineComponent({
    name: "home",
    components: {
        BaseProductCard: BaseProductCard,
    },
    setup: function () {
        var _this = this;
        var route = useRoute();
        var products = ref([]);
        var loading = ref(false);
        var product = ref();
        var fullProductRoute = ref("");
        var pagination = ref();
        var getListOfProducts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        topLoadingToggle(true);
                        return [4 /*yield*/, getProductList(payload)
                                .then(function (res) {
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                products.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value = false;
                        topLoadingToggle(false);
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageChange = function (val) {
            getListOfProducts({
                page: val,
                brand_id: route.params.brandId,
            });
        };
        watch(function () { return route.params; }, function (params) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                getListOfProducts({
                    brand_id: route.params.brandId,
                });
                setCurrentPageBreadcrumbs(((_a = params.brandName) !== null && _a !== void 0 ? _a : "All Brand"), [
                    "Brand",
                ]);
                return [2 /*return*/];
            });
        }); });
        onMounted(function () {
            var _a;
            setCurrentPageBreadcrumbs(((_a = route.params.brandName) !== null && _a !== void 0 ? _a : "All Brand"), ["Brand"]);
            getListOfProducts({ brand_id: route.params.brandId });
        });
        var quickView = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var productModal, productRes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullProductRoute.value = window.location.host + "/product/" + id;
                        productModal = new Modal(document.getElementById("kt_modal_product_detail"), {
                            keyboard: false,
                        });
                        return [4 /*yield*/, showProduct(id)];
                    case 1:
                        productRes = _a.sent();
                        product.value = productRes.data.data;
                        productModal.show();
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            products: products,
            loading: loading,
            quickView: quickView,
            product: product,
            fullProductRoute: fullProductRoute,
            pagination: pagination,
            currentPageChange: currentPageChange,
        };
    },
});
